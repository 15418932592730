<template>
  <div class="resume flex" v-if="resumesList">
    <div
        class="resume_left loads"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
    >
      <section class="header flex">
        <div class="imgs">
          <img
              :src="oneList.imageurl"
              alt
              :class="oneList.imageurl?'avatar':'err_avatar'"
              v-if="oneList.imageurl"
          />
          <img v-else src="~static/column/default.png" alt class="avatar err_avatar"/>
        </div>
        <ul class="info">
          <li class="flex">
            <h1>{{ oneList.userName }}</h1>
          </li>
          <li class="edu">
            <span>
              {{ oneList.sex }}
            </span>
            <span v-if="oneList.age">
              <span>/</span>
              {{ oneList.age }}岁
            </span>
            <span v-if="oneList.edu"><span>/</span>{{oneList.edu}}</span>
            <span v-if="oneList.exp">{{oneList.exp}}</span>
          </li>
          <li class="level" v-for="(item,index) in oneList.disabledType" :key="index">
            <span>
              {{ item.disabledType }}
              <span>/</span>
            </span>
            <span>
              {{ item.disabledLevel }}
            </span>
          </li>
          <li class="link">
            <span v-if="oneList.linkPhone">
              <img src="~/static/center/phone.svg" alt/>
              {{ oneList.linkPhone }}
            </span>
            <span>
              <img src="~/static/center/email.svg" alt/>
              {{ oneList.linkEmail }}
            </span>
            户籍地： <span>{{ oneList.provinceid }}{{ oneList.cityid }}</span>
          </li>
        </ul>
        <span @click="edit(1,'编辑个人信息')" class="edit" style="display:block">
          <img src="~/static/center/editor.png" alt aria-hidden="true" class="middle"/>编辑
        </span>
      </section>
      <section class="personal personInfo relative">
        <div class="title">
          <h2>个人优势</h2>
          <span @click="edit(10,'添加个人优势',undefined)" class="edit">
            <i class="el-icon-edit-outline" aria-hidden="true"></i>编辑
          </span>
        </div>
        <div class="info" v-if="threeList">
          <div class="text" v-html="threeList.description"></div>
        </div>
      </section>
      <section class="intention ">
        <div class="title">
          <h2>求职意向</h2>
        </div>
        <div class="info relative">
          <span @click="edit(2,'编辑求职意向',twoList.id)" class="edit">
            <img src="~/static/center/editor.png" alt class="middle"/>编辑
          </span>
          <ul class="intent">
            <li>
              <p>
                求职状态：
                <span>{{ twoList.jobStatus }}</span>
              </p>
              <p>
                期望行业：
                <span>{{ twoList.jobTop }}</span>
              </p>

              <p>
                期望职业：
                <span>{{ twoList.jobPost }}</span>
              </p>
            </li>
            <li>
              <p>
                工作性质：
                <span>{{ twoList.jobType }}</span>
              </p>
              <p>
                期望地点：
                <span>{{ twoList.provinceid }}</span>
              </p>
              <p>
                税前月薪：
                <span>{{ twoList.salary }}</span>
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section class="experience">
        <div class="title">
          <h2>工作经验</h2>
          <div class="edits" @click="edit(3,'添加工作经历')" style="display:block">
            <i class="el-icon-plus" aria-hidden="true"></i>
            工作经验
          </div>
        </div>
        <div v-for="(item,index) in fourList" :key="index">
          <div class="info">
            <h2 class="relative">
              {{ item.companyName }}
              <small class="time">{{ item.beginTime }}~{{ item.endTime || '至今'}}</small>
              <p>
                <span @click="delate(item.id,1)" class="edit">
                  <i class="el-icon-delete" aria-hidden="true"></i>删除
                </span>
                <span @click="edit(3,'编辑工作经历',item.id)" class="edit">
                  <img src="~/static/center/editor.png" alt class="middle"/>编辑
                </span>
              </p>
            </h2>
            <h3>
              <span>任职岗位：</span>
              <span class="bold">{{ item.jobName }}</span>
            </h3>
            <p class="p1">
              所属部门：<span>{{ item.department }}</span>
              所属行业：<span>{{ item.hyTop }}</span>
            </p>

            <h4>工作职责</h4>
            <div class="text" v-html="item.jobContent"></div>
          </div>
        </div>
      </section>
      <section class="education">
        <div class="title">
          <h2>教育经历</h2>
          <div class="edits" @click="edit(4,'添加教育经历')">
            <i class="el-icon-plus" aria-hidden="true"></i>
            教育经历
          </div>
        </div>
        <div v-for="(item,index) in fiveList" :key="index">
          <div class="info relative">
            <p class="info_edu">
              <span>{{ item.schoolName }}</span>
              <span>{{ item.majorName }}</span>
              <span>{{ item.edu }}</span>
              <span>{{ item.benginTime }}-{{ item.endTime  || '至今'}}</span>
            </p>
            <h4 v-if="item.schoolExperience">在校经历</h4>
            <div class="text">
              <p v-html="item.schoolExperience"></p>
            </div>
            <p class="clicks">
              <span @click="delate(item.id,2)" class="edit">
                <i class="el-icon-delete" aria-hidden="true"></i>删除
              </span>
              <span @click="edit(4,'编辑教育经历',item.id)" class="edit">
                <img src="~/static/center/editor.png" alt class="middle"/>编辑
              </span>
            </p>
          </div>
        </div>
      </section>
      <section class="education train">
        <div class="title">
          <h2>培训经历</h2>
          <div class="edits" @click="edit(5,'添加培训经历',0)">
            <i class="el-icon-plus" aria-hidden="true"></i>
            培训经历
          </div>
        </div>
        <div v-for="(item,index) in sixList" :key="index">
          <div class="info relative">
            <p class="info_edu">
              <span>{{ item.trainOrganization }}</span>
              <span>{{ item.trainCourse }}</span>
              <span>{{ item.beginTime }}-{{ item.endTime  || '至今'}}</span>
            </p>
            <h4 v-if="item.trainContent">培训内容</h4>
            <div class="text">
              <p v-html="item.trainContent"></p>
            </div>
            <p class="clicks">
              <span @click="delate(item.id,3)" class="edit">
                <i class="el-icon-delete" aria-hidden="true"></i>删除
              </span>
              <span @click="edit(5,'编辑培训经历',item.id)" class="edit" v-show="item.status!='1'">
                <img src="~/static/center/editor.png" alt class="middle" aria-hidden="true"/>编辑
              </span>
            </p>
          </div>
        </div>
      </section>
      <section class="education skill">
        <div class="title">
          <h2>专业技能</h2>
          <div class="edits" @click="edit(6,'编辑技能')">
            <i class="el-icon-plus" aria-hidden="true"></i>
            专业技能
          </div>
        </div>
        <div v-for="(item,index) in sevenList" :key="index">
          <div class="info relative">
            <p class="info_edu">
              <span>{{ item.name }}</span>
              <span>{{ item.ing }}</span>
            </p>
            <p class="clicks">
              <span @click="delate(item.id,4)" class="edit">
                <i class="el-icon-delete" aria-hidden="true"></i>删除
              </span>
              <span @click="edit(6,'编辑技能',item.id)" class="edit">
                <img src="~/static/center/editor.png" alt class="middle" aria-hidden="true"/>编辑
              </span>
            </p>
          </div>
        </div>
      </section>
      <!-- <section class="education certificate">
        <div class="title">
          <h2>持有证书</h2>
          <div class="edits" @click="edit(7,'添加证书')">
            <i class="el-icon-plus" aria-hidden="true"></i>
            持有证书
          </div>
        </div>
        <div v-for="(item,index) in eigList" :key="index">
          <div class="info relative">
            <p class="info_edu">
              <viewer :images="[item.imgUrl]" class="inline_block viewer">
                <img :src="item.imgUrl" alt="" class="img">
              </viewer>
            </p>
            <p class="info_edu">
              <span>{{ item.certName }}</span>
              <span>{{ item.certTime }}</span>
            </p>
            <p class="clicks">
              <span @click="delate(item.id,5)" class="edit">
                <i class="el-icon-delete" aria-hidden="true"></i>删除
              </span>
              <span @click="edit(7,'编辑证书',item.id)" class="edit">
                <img src="~/static/center/editor.png" alt class="middle" aria-hidden="true"/>编辑
              </span>
            </p>
          </div>
        </div>
      </section> -->
      <section class="education language">
        <div class="title">
          <h2>语言能力</h2>
          <div class="edits" @click="edit(8,'添加语言能力')">
            <i class="el-icon-plus" aria-hidden="true"></i>
            语言能力
          </div>
        </div>
        <div v-for="(item,index) in nineList" :key="index">
          <div class="info relative">
            <p class="info_edu">
              <span>{{ item.language }}</span>
              <span>
                听说能力
                <i></i>
                {{ item.lsAbility }}
              </span>
              <span>
                读写能力
                <i></i>
                {{ item.swAbility }}
              </span>
            </p>

            <p class="clicks">
              <span @click="delate(item.id,6)" class="edit">
                <i class="el-icon-delete"></i>删除
              </span>
              <span @click="edit(8,'编辑语言能力',item.id)" class="edit">
                <img src="~/static/center/editor.png" alt class="middle"/>编辑
              </span>
            </p>
          </div>
        </div>
      </section>
      <section class="experience project">
        <div class="title">
          <h2>项目经验</h2>
          <div class="edits" @click="edit(9,'添加项目经历')">
            <i class="el-icon-plus" aria-hidden="true"></i>
            项目经验
          </div>
        </div>
        <div v-for="(item,index) in tenList" :key="index">
          <div class="info">
            <h2 class="relative">
              {{ item.projectName }}
              <small class="time">{{ item.benginDate }}-{{ item.endDate }}</small>
              <p class="clicks">
                <span @click="delate(item.id,7)" class="edit">
                  <i class="el-icon-delete" aria-hidden="true"></i>删除
                </span>
                <span @click="edit(9,'编辑项目经历',item.id)" class="edit">
                  <img src="~/static/center/editor.png" alt class="middle" aria-hidden="true"/>编辑
                </span>
              </p>
            </h2>
            <h4>项目描述</h4>
            <div class="text" v-html="item.projectDescript"></div>
            <h4>项目业绩</h4>
            <div class="text" v-html="item.projectResults"></div>
          </div>
        </div>
      </section>
    </div>
    <div class="resume_right">
      <div class="content">
        <ul class="features flex-wrap">
          <li @click="refreshResume">
            <img src="~/static/center/icon1.png" alt aria-hidden="true"/>
            <p>刷新简历</p>
          </li>
          <li @click="goRouter('/public/resume/',memberId,userType)">
            <img src="~/static/center/icon2.png" alt aria-hidden="true"/>
            <p>预览简历</p>
          </li>
          <!--<li>-->
          <!--<img src="~/static/center/icon3.png" alt="">-->
          <!--<p>下载简历</p>-->
          <!--</li>-->
          <!--<li>-->
          <!--<img src="~/static/center/icon4.png" alt="">-->
          <!--<p>附件简历</p>-->
          <!--</li>-->
        </ul>
        <div class="message" v-if="false">
          <div class="top flex">
            <img src="~/static/center/email-fill.png" alt aria-hidden="true"/>
            <div class="text">
              您有
              <span>20</span>条新的投递进展，赶快去看看吧~
            </div>
          </div>
          <!-- <div class="bottom flex">
            <span>完成度</span>
            <div class="schedule">
              <div :style="{width:width+'%'}"></div>
            </div>
            <span>70%</span>
          </div> -->
        </div>
      </div>
    </div>
    <el-dialog
        :title="title"
        :visible.sync="isShow"
        :width="active == 1?'950px':'600px'"
        :before-close="handleClose"
        v-if="isShow"
    >
      <div class="dialog_content">
        <Info v-if="active == 1" :oneList="oneList" @close="close"></Info>
        <Intention v-else-if="active == 2" @close="close" :id="isId"></Intention>
        <work v-else-if="active == 3" @close="close" :id="isId"></work>
        <education v-else-if="active == 4" @close="close" :id="isId"></education>
        <train v-else-if="active == 5" @close="close" :id="isId"></train>
        <skill v-else-if="active == 6" @close="close" :id="isId"></skill>
        <certificate v-else-if="active == 7" @close="close" :id="isId"></certificate>
        <language v-else-if="active == 8" @close="close" :id="isId"></language>
        <project v-else-if="active == 9" @close="close" :id="isId"></project>
        <personInfo v-else @close="close" :id="isId" :description="threeList.description"></personInfo>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Info from "@/components/center/resume/info";
import Intention from "@/components/center/resume/intention";
import work from "@/components/center/resume/work";
import education from "@/components/center/resume/education";
import train from "@/components/center/resume/train";
import skill from "@/components/center/resume/skill";
import certificate from "@/components/center/resume/certificate";
import language from "@/components/center/resume/language";
import project from "@/components/center/resume/project";
import personInfo from "@/components/center/resume/personInfo";
import MulSelect from "@/components/selectComponents/MulSelect";

export default {
  name: "resume",
  components: {
    Info,
    Intention,
    work,
    education,
    train,
    skill,
    certificate,
    language,
    project,
    personInfo,
    MulSelect,
  },
  data() {
    return {
      isId: "",
      loading: false,
      form: {
        jobStatus: "",
      },
      value: "",
      width: "70",
      active: 1,
      isShow: false,
      imageurl: "",
      title: "",
      resumesList: [],
      oneList: [],
      twoList: [],
      threeList: {},
      fourList: [],
      fiveList: [],
      sixList: [],
      sevenList: [],
      eigList: [],
      nineList: [],
      tenList: [],
    };
  },
  methods: {
    // 基本信息
    disableResume() {
      let params = {
        memberId: this.$route.query.id,
        userType: this.$route.query.userType,
      };
      this.loading = true;
      this.$api
          .disableResume(params)
          .then((res) => {
            console.log("我的简历", res.data);
            this.resumesList = res.data.data;
            this.oneList = this.resumesList.disabledOutDTO;
            this.twoList = this.resumesList.resumeExpectOutDTO;
            this.threeList = this.resumesList.resumeDescriptionOutDTO;
            this.fourList = this.resumesList.resumeWorkOutDTOS;
            this.fiveList = this.resumesList.resumeEduOutDTOS;
            this.sixList = this.resumesList.resumeTrainOutDTOS;
            this.sevenList = this.resumesList.resumeSkillOutDTOS;
            this.eigList = this.resumesList.resumeCertOutDTOS;
            this.nineList = this.resumesList.resumeLanguageOutDTOS;
            this.tenList = this.resumesList.resumeProjectOutDTOS;
            this.loading = false;
          })
          .catch((error) => {
            console.log("错误了", error);
            this.loading = false;
          });
    },
    // 删除
    delate(id, status) {
      // 工作经历
      this.id = id;

      this.$confirm("此操作将永久删除该项内容, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(async () => {
            if (status == "1") {
              let res = await this.$api.deleteMyResumeWork({id: this.id});
              if (res.data.success) {
                this.$message.success("删除成功");
              }
            } else if (status == "2") {
              let res = await this.$api.deleteMyResumeEdu({id: this.id});
              if (res.data.success) {
                this.$message.success("删除成功");
              }
            } else if (status == "3") {
              let res = await this.$api.deleteMyResumeTrain({id: this.id});
              if (res.data.success) {
                this.$message.success("删除成功");
              }
            } else if (status == "4") {
              this.id = id;
              let res = await this.$api.deleteMyResumeSkill({id: this.id});
              if (res.data.success) {
                this.$message.success("删除成功");
              }
            } else if (status == "5") {
              this.id = id;
              let res = await this.$api.deleteMyResumeCert({id: this.id});
              if (res.data.success) {
                this.$message.success("删除成功");
              }
            } else if (status == "6") {
              this.id = id;
              let res = await this.$api.deleteMyResumeLanguage({id: this.id});
              if (res.data.success) {
                this.$message.success("删除成功");
              }
            } else {
              let res = await this.$api.deleteMyResumeProject({id: this.id});
              if (res.data.success) {
                this.$message.success("删除成功");
              }
            }
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.disableResume();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    // 刷新简历
    async refreshResume() {
      let res = await this.$api.refreshResume();
      if (res.data.success) {
        this.$message.success("刷新成功");
      }
    },
    setSelectValue(val, key) {
      this.form[key] = val;
    },
    edit(i, title, id) {
      this.active = i;
      this.title = title;
      this.isId = id;
      this.isShow = true;
    },
    remove() {
    },
    goRouter(url, memberId, userType) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: memberId,
          userType: userType,
        },
      });
      window.open(href, "_blank");
    },
    handleClose() {
      this.isShow = false;
    },
    close() {
      this.isShow = false;
    },
  },
  created() {
    this.disableResume(this.$route.query.id);
    if (this.$store.state.login) {
      let memberId = this.$store.state.login.userInfo.id;
      this.memberId = memberId;
      let userType = this.$store.state.login.userInfo.userType;
      this.userType = userType;
    }
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/center/center.less";

/deep/ .el-dialog__body {
  padding: 0px;
}

/deep/ .el-loading-spinner {
  top: 12%;
}
</style>
