<template>
  <div class="education">
    <el-form :model="form" :rules="error" ref="form" label-width="120px" class="form">
      <el-form-item label="培训时间" prop="benginTime" required class="width100">
        <el-date-picker
          v-model="form.benginTime"
          type="month"
          value-format="yyyy/MM"
          class="w200"
          placeholder="请选择开始时间"
          popper-class="eagle-a11y-uncut"
        ></el-date-picker>
        <span class="paddL10 paddR10">至</span>
        <el-date-picker
          v-model="form.endTime"
          type="month"
          value-format="yyyy/MM"
          class="w200"
          placeholder="请选择结束时间"
          popper-class="eagle-a11y-uncut"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="培训机构" prop="trainOrganization" class="w540">
        <el-input v-model="form.trainOrganization" placeholder="请输入培训机构名称"></el-input>
      </el-form-item>
      <el-form-item label="培训课程" prop="trainCourse" class="w540">
        <el-input v-model="form.trainCourse" placeholder="请输入培训课程名称"></el-input>
      </el-form-item>
      <el-form-item label="培训内容" prop="trainContent" class="w540">
        <editor placeholder="请输入" @html="editChange" :testcontent="form.trainContent" height="400"></editor>
      </el-form-item>
      <el-form-item class="buttons text_c marT50">
        <el-button type="info" plain class="w120" @click="halClose()">取消</el-button>
        <el-button type="primary" class="w120" @click="handleSave('form')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import MulSelect from "@/components/selectComponents/MulSelect";
import ThreeLink from "@/components/selectComponents/ThreeLink";
import editor from "@/components/public/editor";
export default {
  name: "train",
  components: {
    MulSelect,
    ThreeLink,
    editor,
  },
  props: ["id"],
  data() {
    return {
      form: {
        benginTime: "",
        endTime: "",
        trainCourse: "",
        trainOrganization: "",
        trainContent: "",
        status:'0',
      },
      error: {
        benginTime: [
          {required: true, message: '请选择时间', trigger: 'change'},
        ],
        trainCourse: [
          {required: true, message: '请输入培训机构名称', trigger: 'blur'},
        ],
        trainOrganization: [
          {required: true, message: '请输入培训课程名称', trigger: 'blur'},
        ],
        trainContent: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
      },
    };
  },
  methods: {
    handleSave(form) {
      this.$refs[form].validate(async (valid) => {
        try {
          if (valid) {
            let res = await this.$api.resumeTrain(this.form);
            if (res.data.success) {
              this.$message.success(res.data.msg);
              this.halClose(0);
              this.form = {};
              this.$parent.$parent.disableResume();
            }
          } else {
            this.$message.error("信息填写不完整！");
            return false;
          }
        } catch (err) {
          alert(err);
        }
      });
    },
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    editChange(val) {
      this.form.trainContent = val;
    },
    halClose(val) {
      this.$emit("close", false);
    },
    async myResumeTrainDetail() {
      let id = this.id;
      try {
        let res = await this.$api.myResumeTrainDetail({ id: this.id });
        console.log(res);

        this.form = res.data.data;
      } catch (error) {
        alert(error);
      }
    },
  },
  created() {
    if (this.id) {
      this.myResumeTrainDetail();
    }
  },
};
</script>

<style scoped>
</style>
