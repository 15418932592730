<template>
  <div class="skill">
    <el-form :model="form" :rules="error" ref="form" label-width="120px" class="form">
      <el-form-item label="语种" prop="language" class="w540">
        <el-input v-model="form.language" placeholder="请输入技能名称"></el-input>
      </el-form-item>
      <el-form-item label="听说能力" prop="lsAbility" class="w540">
        <MulSelect
          :code="'user_ing'"
          class="w420"
          :filterable="false"
          placeholder="请选择听说能力"
          @setMValue="setSelectValue($event,'lsAbility')"
          :defaultValue="form.lsAbility"
        ></MulSelect>
      </el-form-item>
      <el-form-item label="读写能力" prop="swAbility" class="w540">
        <MulSelect
          :code="'user_ing'"
          class="w420"
          :filterable="false"
          placeholder="请选择读写能力"
          @setMValue="setSelectValue($event,'swAbility')"
          :defaultValue="form.swAbility"
        ></MulSelect>
      </el-form-item>
      <el-form-item class="buttons text_c marT50">
        <el-button type="info" plain class="w120" @click="halClose()">取消</el-button>
        <el-button type="primary" class="w120" @click="handleSave('form')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import MulSelect from "@/components/selectComponents/MulSelect";
export default {
  name: "language",
  props: ["id"],
  components: { MulSelect },
  data() {
    return {
      form: {
        language: "",
        lsAbility: "",
        swAbility: "",
      },
      error: {
        language: [
          { required: true, message: "语言不能为空", trigger: "blur" },
        ],
        lsAbility: [
          { required: true, message: "听说不能为空", trigger: "change" },
        ],
        swAbility: [
          { required: true, message: "读写不能为空", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    handleSave(form) {
      let that = this;
      this.$refs[form].validate(async (valid) => {
        try {
          if (valid) {
            let res = await this.$api.resumeLanguage(this.form);
            if (res.data.success) {
              this.$message.success(res.data.msg);
              this.halClose(0);
              that.$parent.$parent.disableResume();
              this.form = {};
            }
          } else {
            this.$message.error("信息填写不完整！");
            return false;
          }
        } catch (err) {
          alert(err);
        }
      });
    },
    setSelectValue(val, key) {
      this.form[key] = val;
    },
    halClose(val) {
      this.$emit("close", false);
    },
    async myResumeLanguageDetail() {
      let id = this.id;
      try {
        let res = await this.$api.myResumeLanguageDetail({ id: this.id });
        console.log(res);
        this.form = res.data.data;
         this.$parent.$parent.disableResume();
      } catch (error) {
        alert(error);
      }
    },
  },
  created() {
    if (this.id) {
      this.myResumeLanguageDetail();
    }
  },
};
</script>

<style scoped>
</style>
