<template>
  <div class="skill">
    <el-form :model="form" :rules="error" ref="form" label-width="120px" class="form">
      <el-form-item label="技能名称" prop="name" class="w540">
        <el-input v-model="form.name" placeholder="请输入技能名称"></el-input>
      </el-form-item>
      <el-form-item label="掌握程度" prop="ing" class="w540">
        <MulSelect
          :code="'user_ing'"
          class="w420"
          placeholder="请选择掌握程度"
          @setMValue="setSelectValue($event,'ing')"
          :defaultValue="form.ing"
        ></MulSelect>
      </el-form-item>
      <el-form-item class="buttons text_c marT50">
        <el-button type="info" plain class="w120" @click="halClose()">取消</el-button>
        <el-button type="primary" class="w120" @click="handleSave('form')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import MulSelect from "@/components/selectComponents/MulSelect";
export default {
  name: "skill",
  props: ["id"],
  components: {MulSelect},
  data() {
    return {
      form: {
        name: "",
        ing: "",
      },
      error: {
        name: [
          { required: true, message: "技能名称不能为空", trigger: "blur" },
        ],
        ing: [
          {
            required: true,
            message: "技能掌握程度不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    handleSave(form) {
      this.$refs[form].validate(async (valid) => {
        try {
          if (valid) {
            let res = await this.$api.resumeSkill(this.form);
            if (res.data.success) {
              this.$message.success(res.data.msg);
              this.halClose(0);
              this.form = {};
               this.$parent.$parent.disableResume();
            }
          } else {
            this.$message.error("信息填写不完整！");
            return false;
          }
        } catch (err) {
          alert(err);
        }
      });
    },
    halClose(val) {
      this.$emit("close", false);
    },
    setSelectValue(val, key) {
      this.form[key] = val;
    },
    async myResumeSkillDetail() {
      let id = this.id;
      try {
        let res = await this.$api.myResumeSkillDetail({ id: this.id });
        console.log(res);

        this.form = res.data.data;
      } catch (error) {
        alert(error);
      }
    },
  },
  created() {
    if (this.id) {
      this.myResumeSkillDetail();
    }
  },
};
</script>

<style scoped>
</style>
