<template>
  <div class="resume_info">
    <el-form :model="form" :rules="rules" ref="form" label-width="120px" class="form">
      <div class="box">
        <div class="left">
          <el-form-item prop="imageurl" class="avatar" label="个人头像">
            <cd-upload v-model="form.imageurl" :isLogo="true" className="avatars" tips="建议使用一寸证件照70*100像素" :on-success="handleAvatarimageurl"/>
          </el-form-item>
          <el-form-item label="姓名" required class="w430">
            <el-input v-model="form.userName" disabled></el-input>
          </el-form-item>
          <el-form-item label="性别" required class="w430">
            <el-input v-model="form.sex" disabled></el-input>
          </el-form-item>
          <el-form-item label="出生日期" required class="w430">
            <el-input v-model="form.birthday" disabled></el-input>
          </el-form-item>
          <el-form-item label="所属辖区" required class="w430">
            <el-input v-model="form.provinceid" disabled></el-input>
          </el-form-item>
          <el-form-item label="身份证号" required class="w430">
            <el-input v-model="form.idNumber" disabled></el-input>
          </el-form-item>
          <el-form-item label="手机号码" required class="w430">
            <el-input v-model="form.linkPhone" disabled></el-input>
            <div class="tip">
              电话即为登录账号，如需修改可直接在
              <router-link to="/setting/" target="_blank">功能设置</router-link>
              中修改
            </div>
          </el-form-item>
          <el-form-item label="残疾证号" required class="w430">
            <el-input v-model="form.dcNumber" disabled></el-input>
          </el-form-item>
        </div>
        <div class="right">
          <el-form-item label="电子邮箱" required class="w430">
            <el-input v-model="form.linkEmail" disabled></el-input>
            <div class="tip">
              邮箱即为默认邮箱号，如需修改可直接在
              <router-link to="/setting/" target="_blank">功能设置</router-link>
              中修改
            </div>
          </el-form-item>
          <el-form-item label="残疾类别" required class="w430">
            <el-input v-model="disabledType" disabled></el-input>
          </el-form-item>
          <el-form-item label="工作时间" required class="w430">
            <el-date-picker
                v-model="form.joinJobDate"
                type="month"
                value-format="yyyy/MM"
                class="width100"
                placeholder="请选择参加工作时间"
                popper-class="eagle-a11y-uncut"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="现住址" prop="addressProvinceid" class="w430">
            <ThreeLink
                :codeType="'provinceid'"
                :codeLevel="'3'"
                placeholder="请选择工作地点"
                class="width100"
                @setMValue="setScasValue($event,form,['addressProvinceid','addressCityid','addressThreeid'])"
                :defaultValue="[form.addressProvinceid,form.addressCityid,form.addressThreeid]"
            ></ThreeLink>
          </el-form-item>
          <el-form-item prop="addressDetail" class="w430">
            <el-input v-model="form.addressDetail" placeholder="请输入详细地址"></el-input>
          </el-form-item>
          <el-form-item label="学历" prop="edu" class="w430">
            <MulSelect
                :code="'user_edu'"
                class="width100"
                :unlimitedEdu="true"
                placeholder="请选择你的学历"
                @setMValue="setSelectValue($event,'edu')"
                :defaultValue="form.edu"
            ></MulSelect>
          </el-form-item>
          <el-form-item label="残疾情况描述">
            <el-input v-model="distypeClob" type="textarea" rows="3"
                      placeholder="请描述影响范围，影响程度等,如：通过助听器可以正常工作"
                      class="wrap_input wrap_input_textarea w336"></el-input>
          </el-form-item>
          <el-form-item label="个人优势" prop="description">
            <editor placeholder="请输入" class="width100" @html="editChange" :testcontent="form.description"
                    height="400"></editor>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="buttons text_c marT50">
      <el-button type="info" plain class="w120" @click="halClose()">取消</el-button>
      <el-button type="primary" class="w120" @click="handleSave('form')">保存</el-button>
    </div>
  </div>
</template>
<script>
import MulSelect from "@/components/selectComponents/MulSelect";
import ThreeLink from "@/components/selectComponents/ThreeLink";
import editor from "@/components/public/editor";

export default {
  name: "info",
  // props: ["oneList"],
  components: {
    MulSelect,
    ThreeLink,
    editor
  },
  data() {
    return {
      disabledType: "",
      distypeClob:'',
      form: {
        threeCityid: "",
        userName: "",
        sex: "",
        birthday: "",
        idNumber: "",
        imageurl: "",
        disTyped: "",
        disabledLevel: "",
        disabledDetail: "",
        disabledType: "",
        linkPhone: "",
        linkEmail: "",
        joinJobDate: "",
        addressProvinceid: "",
        addressCityid: "",
        addressThreeid: "",
        addressDetail: "",
        edu: "",
        edusss: "",
        distypeClob: '',
        description: '',
        _distypes: [],
      },
      rules: {
        imageurl: [
          {required: false, message: "头像不能为空", trigger: "change"},
        ],
        disTyped: [
          {required: true, message: "残疾类别不能为空", trigger: "change"},
        ],
        joinJobDate: [
          {
            required: true,
            message: "工作时间不能为空",
            trigger: "change,blur",
          },
        ],
        addressProvinceid: [
          {required: true, message: "工作地点不能为空", trigger: "change"},
        ],
        addressDetail: [
          {required: true, message: "详细地址不能为空", trigger: "change"},
        ],
        edu: [
          {required: true, message: "详细地址不能为空", trigger: "change"},
        ],
        description: [
          {required: true, message: "未填写时提示文字“请描述具体残疾情况，如影响范围，影响程度等", trigger: "blur"},
        ],
        distypeClob: [
          {required: true, message: "请输入个人优势", trigger: "change"},
        ],
      },
    };
  },
  methods: {
    // 提交表单
    handleSave(form) {
      let dis_types = [];
      let postArr = [];
      for (let i = 0; i < dis_types.length; i++) {
        let y = dis_types[i];
        let postObj = {};
        if (y[0]) {
          postObj["disType"] = y[0];
        }
        if (y[1]) {
          postObj["disTypeLevel"] = y[1];
        }
        postArr.push(postObj);
      }
      if(!this.distypeClob) {
        return   this.$message({
          message: "残疾情况描述未填写",
          center: true,
        });
      }
      this.form.distypeClob = this.distypeClob;
      this.$refs[form].validate(async (valid) => {
        try {
          if (valid) {
            if (this.form.exp) {
              this.form.exp = "";
            }
            let res = await this.$api.saveDisabled(this.form);
            if (res.data.success) {
              this.$message.success(res.data.msg);
              this.halClose(0);
              this.$parent.$parent.disableResume();
            }
          } else {
            this.$message.error("信息填写不完整！");
            return false;
          }
        } catch (err) {
        }
      });
    },
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    setSelectValue(val, key) {
      this.form[key] = val;
    },
    erroupload() {
      this.$message({
        message: "图片上传失败，请重新上传！！",
        center: true,
      });
    },
    beforeAvatarUpload(file) {
      let type = ["image/jpeg", "image/png"];
      const isImage = type.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        this.$message.error("上传图片格式不正确");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isImage && isLt2M;
    },
    handleAvatarimageurl(res, file) {
      this.form.imageurl = res.msg;
    },
    halClose(val) {
      this.$emit("close", false);
    },
    editChange(val) {
      this.form.description = val;
    },
    async getMyInfo() {
      // let disabledId=this.id
      try {
        let res = await this.$api.getMyInfo();
        this.form = res.data.data;
        this.form.disabledType = res.data.data.disabledType[0];
        if (this.form.resumeDescription) {
          this.distypeClob = this.form.resumeDescription.distypeClob;
          this.form.description = this.form.resumeDescription.description;
        }
        this.form.jobStatus = null;
      } catch (error) {
        alert(error);
      }
    },
    async getMyDisAbledInfo() {
      let res = await this.$api.getMyDisAbledInfo();
      if (res.data.success) {
        let data = res.data.data;
        this.disabledType = data.disabledType[0].disabledType + data.disabledType[0].disabledLevel + (data.disabledType[0].disabledDetail || '')
      } else {
        console.log("错误了", error);
      }
    },
  },
  mounted() {
    this.getMyDisAbledInfo();
    this.getMyInfo();
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/center/resumeEdit.less";

/deep/ .el-dialog__body {
  padding: 0px;

  .box {
    display: flex;
  }
}
</style>
